.handsontable table {
  width: 100%;
  box-sizing: border-box;
}

.handsontable {
  position: relative;
}

.handsontable .hide {
  display: none;
}

.handsontable .relative {
  position: relative;
}

.handsontable .wtHider {
  width: 0;
}

.handsontable .wtSpreader {
  position: relative;
  width: 0; /*must be 0, otherwise blank space appears in scroll demo after scrolling max to the right */
  height: auto;
}

.handsontable input,
.handsontable textarea {
  min-height: initial;
}

.handsontable table.htCore {
  border-collapse: separate;
  /* it must be separate, otherwise there are offset miscalculations in WebKit: http://stackoverflow.com/questions/2655987/border-collapse-differences-in-ff-and-webkit */
  /* this actually only changes appearance of user selection - does not make text unselectable */
  /* -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none; */ /* no browser supports unprefixed version */
  border-spacing: 0;
  margin: 0;
  border-width: 0;
  table-layout: fixed;
  width: 0;
  outline-width: 0;
  cursor: default;
  /* reset bootstrap table style. for more info see: https://github.com/handsontable/handsontable/issues/224 */
  max-width: none;
  max-height: none;
}

.handsontable col {
  width: 50px;
}

.handsontable col.rowHeader {
  width: 50px;
}

.handsontable th,
.handsontable td {
  /* added by Michael */
  font-size: 14px;
  max-height: 50px;
  overflow: hidden;
  /* ==== */
  border-top-width: 0;
  border-left-width: 0;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* height: 22px; ?? */
  empty-cells: show;
  line-height: 21px;
  padding: 0 4px 0 4px;
  /* top, bottom padding different than 0 is handled poorly by FF with HTML5 doctype */
  background-color: #fff;
  vertical-align: top;
  overflow: hidden;
  outline-width: 0;
  white-space: pre-line;
  /* preserve new line character in cell */
}

.handsontable th:last-child {
  /*Foundation framework fix*/
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.handsontable th:first-child,
.handsontable th:nth-child(2),
.handsontable td:first-of-type {
  border-left: 1px solid #ccc;
}

.handsontable.htRowHeaders thead tr th:nth-child(2) {
  border-left: 1px solid #ccc;
}

.handsontable tr:first-child th,
.handsontable tr:first-child td {
  border-top: 1px solid #ccc;
}

.ht_master:not(.innerBorderLeft):not(.emptyColumns) ~ .handsontable tbody tr th,
.ht_master:not(.innerBorderLeft):not(.emptyColumns)
  ~ .handsontable:not(.ht_clone_top)
  thead
  tr
  th:first-child {
  border-right-width: 0;
}

/*
innerBorderTop - Property controlled by top overlay
innerBorderBottom - Property controlled by bottom overlay
 */
.ht_master:not(.innerBorderTop):not(.innerBorderBottom) thead tr:last-child th,
.ht_master:not(.innerBorderTop):not(.innerBorderBottom) ~ .handsontable thead tr:last-child th,
.ht_master:not(.innerBorderTop):not(.innerBorderBottom) thead tr.lastChild th,
.ht_master:not(.innerBorderTop):not(.innerBorderBottom) ~ .handsontable thead tr.lastChild th {
  border-bottom-width: 0;
}

.handsontable th {
  background-color: #f0f0f0;
  color: #222;
  text-align: left;
  font-weight: normal;
  white-space: nowrap;
}

.handsontable thead th {
  padding: 4px;
}

.handsontable th.active {
  background-color: #ccc;
}
.handsontable thead th .relative {
  padding: 2px 4px;
}

.handsontable span.colHeader {
  display: inline-block;
  line-height: 1.1;
}

/* Selection */
.handsontable .wtBorder {
  position: absolute;
  font-size: 0;
}
.handsontable .wtBorder.hidden {
  display: none !important;
}

/* A layer order of the selection types */
.handsontable .wtBorder.current {
  z-index: 10;
}
.handsontable .wtBorder.area {
  z-index: 8;
}
.handsontable .wtBorder.fill {
  z-index: 6;
}

/* fill handle */

.handsontable .wtBorder.corner {
  font-size: 0;
  cursor: crosshair;
}

.ht_clone_master {
  z-index: 100;
}

.ht_clone_right {
  z-index: 110;
}

.ht_clone_left {
  z-index: 120;
}

.ht_clone_bottom {
  z-index: 130;
}

.ht_clone_bottom_right_corner {
  z-index: 140;
}

.ht_clone_bottom_left_corner {
  z-index: 150;
}

.ht_clone_top {
  z-index: 160;
}

.ht_clone_top_right_corner {
  z-index: 170;
}

.ht_clone_top_left_corner {
  z-index: 180;
}

/*
  Cell borders
  */
.handsontable tbody tr th:nth-last-child(2) {
  border-right: 1px solid #ccc;
}

.ht_clone_top_left_corner thead tr th:nth-last-child(2) {
  border-right: 1px solid #ccc;
}

.handsontable col.hidden {
  width: 0 !important;
}

.handsontable tr.hidden,
.handsontable tr.hidden td,
.handsontable tr.hidden th {
  display: none;
}

.ht_master,
.ht_clone_left,
.ht_clone_top,
.ht_clone_bottom {
  overflow: hidden;
}

.ht_master .wtHolder {
  overflow: auto;
}

.handsontable .ht_master thead,
.handsontable .ht_master tr th,
.handsontable .ht_clone_left thead {
  visibility: hidden;
}

.ht_clone_top .wtHolder,
.ht_clone_left .wtHolder,
.ht_clone_bottom .wtHolder {
  overflow: hidden;
}
