body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* customize react-day-picker */
:root {
  --rdp-accent-color: #8e338e;
  --rdp-cell-size: 36px;
}

/*
when migrating to ChakraUI 1.7.2 we lose the `vertical-align: middle` applied to SVG
Unfortunately it's not possible to use the theme to fix this
because there is no `Icon` component in the theme
TODO check if we need this fix with the next versions of ChakraUI
*/
.chakra-icon {
  vertical-align: middle;
}

/*
dirty hack to override the style provided by WinBox CSS
TODO import only the JS code from and use our own CSS?
*/
.chakra-ui-light .wb-title {
  font-family: inherit;
  font-size: inherit;
}

@keyframes popup {
  0% {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
.winbox.modern {
  background: linear-gradient(90deg, #ff00f0, #0050ff);
  border-radius: 12px 12px 0 0;
  animation: popup 0.3s cubic-bezier(0.3, 1, 0.3, 1) backwards;
}
.winbox.modern:not(.min):not(.focus) {
  background: #666;
}
@media (hover: hover) {
  .winbox.modern.min:not(:hover) {
    background: #666;
  }
  .winbox.modern .wb-icon * {
    opacity: 0.65;
  }
  .winbox.modern .wb-icon :hover {
    opacity: 1;
  }
}
@media (hover: none) {
  .winbox.modern.min {
    background: #666;
  }
}
.winbox.modern.max {
  border-radius: 0;
}
.winbox.modern .wb-title {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}
.winbox.modern .wb-body {
  margin: 4px;
  color: #fff;
  background: #131820;
}
.winbox.modern .wb-body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.winbox.modern .wb-body::-webkit-scrollbar-track {
  background: 0 0;
}
.winbox.modern .wb-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #263040;
}
.winbox.modern .wb-body::-webkit-scrollbar-thumb:window-inactive {
  background: #181f2a;
}
.winbox.modern .wb-body::-webkit-scrollbar-corner {
  background: 0 0;
}
