/*
Cystomized from from https://github.com/caplin/FlexLayout/tree/master/style/light.css
*/
.flexlayout__layout {
  --color-text: black;
  --color-text: inherit;
  --color-background: white;
  --color-base: white;
  --color-1: #f7f7f7;
  --color-2: #f0f0f0;
  --color-3: #e9e9e9;
  --color-4: #e2e2e2;
  --color-5: #dbdbdb;
  --color-6: #d4d4d4;
  --color-drag1: #5f86c4;
  --color-drag2: #77a677;
  --color-drag1-background: rgba(95, 134, 196, 0.1);
  --color-drag2-background: rgba(119, 166, 119, 0.075);
  --font-size: medium;
  /* --font-family: Roboto, Arial, sans-serif; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
}
.flexlayout__splitter {
  background-color: var(--color-1);
  background-color: var(--chakra-colors-gray-200);
  /* background-color: red; */
}
@media (hover: hover) {
  .flexlayout__splitter:hover {
    transition: background-color ease-in 0.1s;
    transition-delay: 0.05s;
    background-color: var(--color-4);
    background-color: var(--chakra-colors-gray-300);
  }
}
.flexlayout__splitter_border {
  z-index: 10;
}
.flexlayout__splitter_drag {
  z-index: 1000;
  background-color: var(--color-4);
}
.flexlayout__splitter_extra {
  background-color: transparent;
}
.flexlayout__outline_rect {
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  border: 2px solid var(--color-drag1);
  background: var(--color-drag1-background);
  border-radius: 5px;
  z-index: 1000;
}
.flexlayout__outline_rect_edge {
  pointer-events: none;
  border: 2px solid var(--color-drag2);
  background: var(--color-drag2-background);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
}
.flexlayout__edge_rect {
  position: absolute;
  z-index: 1000;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: gray;
  pointer-events: none;
}
.flexlayout__drag_rect {
  position: absolute;
  cursor: move;
  color: var(--color-text);
  background-color: var(--color-1);
  border: 2px solid var(--color-4);
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1000;
  box-sizing: border-box;
  opacity: 0.9;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5em 1em;
  word-wrap: break-word;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__tabset {
  overflow: hidden;
  background-color: var(--color-1);
  box-sizing: border-box;
  font-size: var(--font-size);
  font-family: var(--font-family);
  background-color: var(--color-background);
}
.flexlayout__tabset_header {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  padding: 3px 3px 3px 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-3);
  color: var(--color-text);
  background-color: var(--color-background);
}
.flexlayout__tabset_header_content {
  flex-grow: 1;
}
.flexlayout__tabset_tabbar_outer {
  box-sizing: border-box;
  background-color: var(--color-1);
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  background-color: var(--color-background);
}
.flexlayout__tabset_tabbar_outer_top {
  border-bottom: 1px solid var(--color-3);
}
.flexlayout__tabset_tabbar_outer_bottom {
  border-top: 1px solid var(--color-3);
}
.flexlayout__tabset_tabbar_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.flexlayout__tabset_tabbar_inner_tab_container {
  display: flex;
  gap: 4px;
  /* padding-left: 4px; */
  padding-left: 2px;
  padding-right: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
}
.flexlayout__tabset_tabbar_inner_tab_container_top {
  border-top: 2px solid transparent;
}
.flexlayout__tabset_tabbar_inner_tab_container_bottom {
  border-bottom: 2px solid transparent;
}
/* .flexlayout__tabset-selected {
  background-color: var(--color-1);
} */
/* .flexlayout__tabset-maximized {
  background-color: var(--color-6);
} */
.flexlayout__tab_button_stamp {
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
  white-space: nowrap;
  box-sizing: border-box;
}
.flexlayout__tab {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: var(--color-text);
  background-color: var(--color-background);
}
.flexlayout__tab_button {
  display: flex;
  gap: 0.3em;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 0.5em;
  padding: 0.25px 0.75rem;
  cursor: pointer;
  background-color: var(--chakra-colors-gray-50);
  border-bottom: 3px solid var(--chakra-colors-gray-200);
}
.flexlayout__tab_button--selected {
  background-color: var(--color-4);
  background-color: var(--chakra-colors-primary-100);
  color: var(--color-text);
  color: var(--chakra-colors-primary-500);
  border-bottom: 3px solid var(--chakra-colors-primary-200);
}
@media (hover: hover) {
  .flexlayout__tab_button:hover {
    background-color: var(--color-4);
    background-color: var(--chakra-colors-primary-100);
    color: var(--color-text);
    color: var(--chakra-colors-primary-500);
    border-bottom: 3px solid var(--chakra-colors-primary-200);
  }
}
.flexlayout__tab_button--unselected {
  color: gray;
  color: var(--chakra-colors-gray-500);
}
.flexlayout__tab_button_leading {
  display: flex;
}
.flexlayout__tab_button_content {
  display: flex;
}
.flexlayout__tab_button_textbox {
  border: none;
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--color-text);
  background-color: var(--color-5);
  border: 1px inset var(--color-1);
  border-radius: 3px;
  width: 10em;
}
.flexlayout__tab_button_textbox:focus {
  outline: none;
}
.flexlayout__tab_button_trailing {
  display: flex;
  /* visibility: hidden; */
  opacity: 0.5;
  border-radius: 4px;
}
.flexlayout__tab_button_trailing:hover {
  background-color: var(--color-3);
}
@media (hover: hover) {
  .flexlayout__tab_button:hover .flexlayout__tab_button_trailing {
    /* visibility: visible; */
    opacity: 1;
  }
}
.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
  /* visibility: visible; */
  opacity: 1;
}
.flexlayout__tab_button_overflow {
  display: flex;
  align-items: center;
  border: none;
  color: gray;
  font-size: inherit;
  background-color: transparent;
}
.flexlayout__tab_toolbar {
  display: flex;
  align-items: center;
  gap: 0.3em;
  padding-left: 0.5em;
  padding-right: 0.3em;
}
.flexlayout__tab_toolbar_button {
  border: none;
  outline: none;
  font-size: inherit;
  margin: 0px;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;
}
@media (hover: hover) {
  .flexlayout__tab_toolbar_button:hover {
    background-color: var(--color-2);
  }
}
.flexlayout__tab_toolbar_sticky_buttons_container {
  display: flex;
  gap: 0.3em;
  padding-left: 2px;
  align-items: center;
}
.flexlayout__tab_floating {
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  color: var(--color-text);
  background-color: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexlayout__tab_floating_inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexlayout__tab_floating_inner div {
  margin-bottom: 5px;
  text-align: center;
}
.flexlayout__tab_floating_inner div a {
  color: royalblue;
}
.flexlayout__border {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  font-size: var(--font-size);
  font-family: var(--font-family);
  background-color: var(--color-background);
}
.flexlayout__border_top {
  border-bottom: 1px solid var(--color-3);
  align-items: center;
}
.flexlayout__border_bottom {
  border-top: 1px solid var(--color-3);
  align-items: center;
}
.flexlayout__border_left {
  border-right: 1px solid var(--color-3);
  align-content: center;
  flex-direction: column;
}
.flexlayout__border_right {
  border-left: 1px solid var(--color-3);
  align-content: center;
  flex-direction: column;
}
.flexlayout__border_inner {
  position: relative;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
}
.flexlayout__border_inner_tab_container {
  white-space: nowrap;
  display: flex;
  gap: 4px;
  padding-left: 2px;
  padding-right: 2px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10000px;
}
.flexlayout__border_inner_tab_container_right {
  transform-origin: top left;
  transform: rotate(90deg);
}
.flexlayout__border_inner_tab_container_left {
  flex-direction: row-reverse;
  transform-origin: top right;
  transform: rotate(-90deg);
}
.flexlayout__border_button {
  display: flex;
  gap: 0.3em;
  align-items: center;
  cursor: pointer;
  padding: 3px 0.5em;
  margin: 2px 0px;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: var(--color-2);
}
.flexlayout__border_button--selected {
  background-color: var(--color-4);
  color: var(--color-text);
}
@media (hover: hover) {
  .flexlayout__border_button:hover {
    background-color: var(--color-4);
    color: var(--color-text);
  }
}
.flexlayout__border_button--unselected {
  color: gray;
}
.flexlayout__border_button_leading {
  display: flex;
}
.flexlayout__border_button_content {
  display: flex;
}
.flexlayout__border_button_trailing {
  display: flex;
  border-radius: 4px;
  visibility: hidden;
}
.flexlayout__border_button_trailing:hover {
  background-color: var(--color-3);
}
@media (hover: hover) {
  .flexlayout__border_button:hover .flexlayout__border_button_trailing {
    visibility: visible;
  }
}
.flexlayout__border_button--selected .flexlayout__border_button_trailing {
  visibility: visible;
}
.flexlayout__border_toolbar {
  display: flex;
  gap: 0.3em;
  align-items: center;
}
.flexlayout__border_toolbar_left,
.flexlayout__border_toolbar_right {
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.3em;
}
.flexlayout__border_toolbar_top,
.flexlayout__border_toolbar_bottom {
  padding-left: 0.5em;
  padding-right: 0.3em;
}
.flexlayout__border_toolbar_button {
  border: none;
  outline: none;
  font-size: inherit;
  background-color: transparent;
  border-radius: 4px;
  padding: 1px;
}
@media (hover: hover) {
  .flexlayout__border_toolbar_button:hover {
    background-color: var(--color-2);
  }
}
.flexlayout__border_toolbar_button_overflow {
  display: flex;
  align-items: center;
  border: none;
  color: gray;
  font-size: inherit;
  background-color: transparent;
}
.flexlayout__popup_menu {
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__popup_menu_item {
  padding: 2px 0.5em;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
}
@media (hover: hover) {
  .flexlayout__popup_menu_item:hover {
    background-color: var(--color-6);
  }
}
.flexlayout__popup_menu_container {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--color-6);
  color: var(--color-text);
  background: var(--color-background);
  border-radius: 3px;
  position: absolute;
  z-index: 1000;
  max-height: 50%;
  min-width: 100px;
  overflow: auto;
  padding: 2px;
}
.flexlayout__floating_window _body {
  height: 100%;
}
.flexlayout__floating_window_content {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.flexlayout__floating_window_tab {
  overflow: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  background-color: var(--color-background);
  color: var(--color-text);
}
.flexlayout__error_boundary_container {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
}
.flexlayout__error_boundary_content {
  display: flex;
  align-items: center;
}
.flexlayout__tabset_sizer {
  padding-top: 5px;
  padding-bottom: 3px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__tabset_header_sizer {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}
.flexlayout__border_sizer {
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: var(--font-size);
  font-family: var(--font-family);
}

/*# sourceMappingURL=light.css.map */
