/* Customize the minimap displayed in the bottom right corner */
.openseadragon-container .navigator {
  margin-right: 12px !important;
  margin-bottom: 5px !important;
  background: #111a39 !important;
  border: 1px solid rgba(255, 255, 255, 0.88) !important;
}

/* Get rid of the outline effect applied on the image viewer when it's clicked, in recent versions of Chrome */
.openseadragon-canvas:focus {
  outline: none;
}
